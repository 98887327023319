<template>
    <div :class="classes">
        <slot />
    </div>
</template>

<script>
export default {
    name: "SectionContent",
    data() {
        return {
            baseClasses: "",
        };
    },
    computed: {
        classes() {
            return `section-content ${this.baseClasses}`;
        },
    },
};
</script>
